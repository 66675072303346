import axios from 'axios';
import authHeader from "@/service/AuthHeader";
import getEnvironment from "@/service/Environment";

const API_LOGGED_IN_URL = getEnvironment().API_LOGGED_IN_URL + 'global-date-exception';

export default class SettingService {
    getAll() {
        return axios.get(API_LOGGED_IN_URL + '/list', { headers: authHeader() });
    }

    get(id) {
        return axios.get(API_LOGGED_IN_URL + `/view/${id}`, { headers: authHeader() });
    }

    create(data) {
        const header = Object.assign({'Content-Type': 'application/json'}, authHeader());
        return axios.post(API_LOGGED_IN_URL + '/save', data, { headers:  header });
    }

    delete(id) {
        return axios.delete(API_LOGGED_IN_URL + `/delete/${id}`, { headers: authHeader() });
    }
}
