<template>
    <div class="grid">
        <div class="col-12">
            <div class="card text-center" v-if="permissionDenied">
                <h4>A kért oldal megtekintéséhez nincs jogosultsága!</h4>
            </div>
            <div class="card" v-if="!permissionDenied">
                <Toast/>
                <Toolbar class="mb-4">
                    <template v-slot:start>
                        <div class="my-2">
                            <Button label="Új" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew"/>
                        </div>
                    </template>
                    <template v-slot:end>
                        <Button label="Export" icon="pi pi-download" class="p-button-help" @click="exportToExcel"/>
                    </template>
                </Toolbar>

                <DataTable ref="dt" :value="settings" v-model:selection="selectedSettings" dataKey="id"
                           :paginator="true" :rows="10" :filters="filters" :loading="loading"
                           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                           :rowsPerPageOptions="[5,10,25]"
                           currentPageReportTemplate="" responsiveLayout="scroll" filterDisplay="row"
                           v-model:filters="filters"
                           :globalFilterFields="['date']">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <h5 class="m-0">Globális dátum kivételek <br> <strong><small>Összesen: {{ total }}</small></strong></h5>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global'].value" placeholder="Keresés..."/>
                            </span>
                        </div>
                    </template>

                    <Column selectionMode="multiple" headerStyle="width: 1rem"></Column>
                    <Column field="id" header="#" :sortable="true" headerStyle="width:2%; min-width:15rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">#</span>
                            {{ slotProps.data.id }}
                        </template>
                    </Column>
                    <Column field="date" header="Dátum" :sortable="true" headerStyle="width:2%; min-width:15rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Dátum</span>
                            {{ slotProps.data.date }}
                        </template>
                    </Column>
                    <Column field="createdAt" header="Létrehozás dátuma" :sortable="true"
                            headerStyle="width:15%; min-width:15rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Létrehozás dátuma</span>
                            {{ slotProps.data.createdAt }}
                        </template>
                    </Column>
                    <Column headerStyle="min-width:10rem;">
                        <template #body="slotProps">
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger mt-2" @click="confirmDelete(slotProps.data)" />
                        </template>
                    </Column>
                    <template #empty>
                        Nincs egyetlen globális dátum kivétel sem.
                    </template>
                </DataTable>

                <Dialog v-model:visible="settingDialog" :style="{width: '800px'}" header="Globális dátum kivétel" :modal="true"
                        class="p-fluid">
                    <div class="field col">
                        <label for="name">Dátum</label> <br>
                        <Calendar inputId="calendar" :class="{'p-invalid': submitted && !setting.date}"
                                  v-model="setting.date" :manualInput="false" dateFormat="yy. mm. dd."></Calendar>
                        <small class="p-invalid" v-if="submitted && !setting.date">Dátum megadása kötelező.</small>
                    </div>

                    <template #footer>
                        <Button label="Mégse" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
                        <Button label="Mentés" icon="pi pi-check" class="p-button-success" @click="saveSetting"
                                :loading="loading"/>
                    </template>
                </Dialog>

                <Dialog v-model:visible="deleteSettingDialog" :style="{width: '550px'}" header="Törlés megerősítése"
                        :modal="true">
                    <div class="flex align-items-center justify-content-center mt-3">
                        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
                        <span>Biztosan törölni szeretné a következő dátumot: <br> <b>{{ setting.date }}</b>?</span>
                    </div>
                    <template #footer>
                        <Button label="Nem" icon="pi pi-times" class="p-button-text"
                                @click="deleteSettingDialog = false"/>
                        <Button label="Igen" icon="pi pi-check" class="p-button-danger" @click="deleteSetting"/>
                    </template>
                </Dialog>
            </div>
        </div>
    </div>

</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import SettingService from "@/service/SettingService";
import * as dayjs from "dayjs";
import ExcelJS from 'exceljs';

export default {
    data() {
        return {
            total: 0,
            settings: null,
            setting: {},
            filters: {},
            settingDialog: false,
            showSettingDialog: false,
            selectedSettings: null,
            submitted: false,
            loading: true,
            deleteSettingDialog: false,
            permissionDenied: true,
        }
    },
    settingService: null,
    created() {
        this.settingService = new SettingService();
        this.initFilters();
    },
    mounted() {
        let user = JSON.parse(localStorage.getItem('userData'));

        if (user.role === 1) {
            this.permissionDenied = true;
        } else {
            this.permissionDenied = false;
            this.getAll();
        }
    },
    methods: {
        getAll() {
            this.settingService.getAll().then(response => {
                this.total = response.data.total
                this.settings = response.data.data
                this.loading = false;
            });
        },
        openNew() {
            this.setting = {};
            this.submitted = false;
            this.settingDialog = true;
        },
        hideDialog() {
            this.settingDialog = false;
            this.submitted = false;
        },
        saveSetting() {
            this.submitted = true;

            if (!this.setting.date) {
                return;
            }

            this.loading = true;

            this.settingService.create({
                date: this.setting.date instanceof Date ?
                    new Intl.DateTimeFormat('ko-KR').format(this.setting.date) : this.setting.date
            }).then(() => {
                this.settingDialog = false;
                this.setting = {};
                this.getAll()

                this.$toast.add({
                    severity: 'success',
                    summary: 'Sikeres művelet!',
                    detail: 'A globális dátum kivétel felvétele sikeres volt.',
                    life: 5000
                });

                this.loading = false;
            }).catch(e => {
                this.$toast.add({
                    severity: "error",
                    summary: "Sikertelen művelet!",
                    detail: e.response.data.error,
                    life: 6000,
                });

                this.loading = false;
            });
        },
        confirmDelete(setting) {
            this.setting = setting;
            this.deleteSettingDialog = true;
        },
        deleteSetting() {
            this.loading = true;
            this.deleteSettingDialog = false;

            this.settingService.delete(this.setting.id).then(() => {
                this.settingDialog = false;
                this.setting = {};
                this.getAll()

                this.$toast.add({
                    severity: 'success',
                    summary: 'Sikeres művelet!',
                    detail: 'A globális dátum kivétel törlése sikeres volt.',
                    life: 5000
                });

                this.loading = false;
            }).catch(e => {
                this.$toast.add({
                    severity: "error",
                    summary: "Sikertelen művelet!",
                    detail: e.response.data.error,
                    life: 6000,
                });

                this.loading = false;
            });
        },
        async exportToExcel() {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Sheet 1');
            worksheet.columns = [
                { header: '#', key: 'id', width: 10 },
                { header: 'Dátum', key: 'date', width: 20 },
                { header: 'Létrehozás dátuma', key: 'createdAt', width: 20 }
            ];

            const data = this.settings === null ? [] : this.settings;

            data.forEach(row => {
                worksheet.addRow(Object.values(row));
            });

            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            const today = dayjs().format('YYYY-MM-DD');
            const filename = `${today}_globalis_datum_kivetelek.xlsx`;
            a.download = filename;
            a.click();
        },
        initFilters() {
            this.filters = {
                'global': {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS
                },
                'date': {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/badges.scss';
</style>